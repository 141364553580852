// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EffraLight;
  src: url("../assets/fonts/effra-light/cb125877edce66887e4e20644cd84bd7.eot"); /* IE9*/
  src: url("../assets/fonts/effra-light/cb125877edce66887e4e20644cd84bd7.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/effra-light/cb125877edce66887e4e20644cd84bd7.woff2") format("woff2"), /* chrome、firefox */
  url("../assets/fonts/effra-light/cb125877edce66887e4e20644cd84bd7.woff") format("woff"), /* chrome、firefox */
  url("../assets/fonts/effra-light/cb125877edce66887e4e20644cd84bd7.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../assets/fonts/effra-light/cb125877edce66887e4e20644cd84bd7.svg#Effra Light") format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: 'effra';
  src: url('../assets/fonts/effra/effra_std_bd-webfont.woff2') format('woff2'),
       url('../assets/fonts/effra/effra_std_bd-webfont.woff') format('woff'),
       url('../assets/fonts/effra/effra_std_bd-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'effra';
  src: url('../assets/fonts/effra/effra_std_rg-webfont.woff2') format('woff2'),
       url('../assets/fonts/effra/effra_std_rg-webfont.woff') format('woff'),
       url('../assets/fonts/effra/effra_std_rg-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'effra';
  src: url('../assets/fonts/effra/l') format('woff2'),
       url('../assets/fonts/effra/d.woff') format('woff'),
       url('../assets/fonts/effra/a') format('opentype');
  font-weight: 500;
  font-style: normal;
}

/*
 * Droid Arabic Kufi (Arabic) http://www.google.com/fonts/earlyaccess
 */
 @font-face {
  font-family: 'Droid Arabic Kufi';
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.eot);
  src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.eot?#iefix) format('embedded-opentype'),
       url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.woff2) format('woff2'),
       url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.woff) format('woff'),
       url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Droid Arabic Kufi';
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.eot);
  src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.eot?#iefix) format('embedded-opentype'),
       url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.woff2) format('woff2'),
       url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.woff) format('woff'),
       url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.ttf) format('truetype');
}

/** Ionic CSS Variables **/
:root {
  body.day {
    background: #ffffff;
    --ion-background-color: #ffffff;
    --ion-tab-bar-background: #fff;

    --ion-card-background: #fff;

    --ion-color-custom-1: #292f38;
    --ion-color-custom-2: #bdbdbd;
    --ion-color-custom-3: #d8e0f3;
    --ion-color-custom-4: #e2e2e6;
    --ion-color-custom-5: #68687a;
    --ion-color-custom-6: #23233d;
    --ion-color-custom-7: #7d7d8d;
    --ion-color-custom-8: #f5f5f7;
    --ion-color-custom-9: #f7f8fa;
    --ion-color-custom-10: #333333;
    --ion-color-custom-11: #fff7f0;
    --ion-color-custom-12: #fff2f2;
    --ion-color-custom-13: #8ab4f8;
    --ion-color-custom-14: #4c70f2;
    
    --ion-color-custom-15: #231f20;
    --ion-color-custom-16: #fb8d33;
    --ion-color-custom-17: #4b4b61;
    --ion-color-custom-18: #4f4f4f;
    --ion-color-custom-19: #f24459;
    --ion-color-custom-20: #eeeef0;
    --ion-color-custom-21: #c5c5cc;
    --ion-color-custom-22: #edfcf3;
    --ion-color-custom-23: #eb5757;
    --ion-color-custom-24: #29b564;
    --ion-color-custom-25: #828282;
    --ion-color-custom-26: #03a2cd;
    --ion-color-custom-27: #9b9b9b;
    --ion-color-custom-28: #f2f2f2;
    --ion-color-custom-29: #2CB564;
    --ion-color-custom-30: #f2c94c;
    --ion-color-custom-31: #f2994a;
    --ion-color-custom-32: #219653;
    --ion-color-custom-33: #2f80ed;
    --ion-color-custom-34: #3f5dca;
    --ion-color-custom-35: #202124;
    --ion-color-custom-36: #e32c69;
    --ion-color-custom-37: #4a4a4a;
    --ion-color-custom-38: #6B6B6B;
    --ion-color-custom-39: #B4B4B4;
    --ion-color-custom-40: #6fcf97;
    --ion-color-custom-41: #eb5757;
    --ion-color-custom-42: #fafafa;
    --ion-color-custom-43: #f5f7ff;
    --ion-color-custom-44: #263879;
    --ion-color-custom-45: #ca7f3e;
    --ion-color-custom-46: #c3c3c3;
    --ion-color-custom-47: #7367f0;
    --ion-color-custom-48: #28c76f;
    --ion-color-custom-49: #ea5455;
    --ion-color-custom-50: #ff9f43;
    --ion-color-custom-51: #2c2c2c;
    --ion-color-custom-52: #f5f7fb;
    --ion-color-custom-53: #4354a4;
    --ion-color-custom-54: #79a336;
    --ion-color-custom-55: #212529;
    --ion-color-custom-56: #fcfcfc;

    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;
    
    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }

  /*
   * @media (prefers-color-scheme: dark) {
   * Dark Colors
   * -------------------------------------------
   */

  body.night {
    background: #000000;
    
    --ck-color-base-background: var(--background);
    --ck-color-toolbar-background: var(--background);

    --ion-card-background: #383a3e;

    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  body.night .ios {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: var(--ion-color-custom-10);
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

   body.night .md {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }
}
