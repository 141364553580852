/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//@tailwind base;
//@tailwind components;
@tailwind utilities;

html,
body {
  font-family: Inter, sans-serif !important;
  --ion-font-family: Inter, sans-serif !important;

  ion-label[position="floating"],
  ion-label[position="stacked"] {
      transform-origin: left top;
  //    transform: translate3d(0%, 40%, 0) scale(0.75) !important;
  }
}

.clearfix {
  clear: both;
  display: table;
  content: "";
}
ion-list {
  background: transparent !important;
}

ion-tabs ion-back-button {
  display: none !important;
}

.hidden {
  display: none;
  //transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}

.blank_bg {
    background: none !important;
    color: var(--ion-color-primary) !important;
}

.error-wrapper {
    display: block;
    padding-left: 16px;
    padding-top: 5px;
    color: red;
}

.text-danger {
  color: var(--ion-color-warning) !important;
}

.text-warning {
  color: var(--ion-color-warning) !important;
}

.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

ion-back-button {
  background: var(--ion-color-custom-8);
  border-radius: 100%;
  height: 40px;
  width: 40px;
  padding: 0;
  --padding-start: 0;
  --padding-end: 0;
  /* font-size: 12px; */
  //margin-top: 44px;
  min-width: 40px;
  //margin-inline-start: 16px;
  --icon-font-size: 16px;

  /*ion-icon {
    fill: #7D7D8D;
  }*/
}

.day ion-footer {
  background: linear-gradient(180deg, rgba(247, 248, 250, 0) 0%, #F7F8FA 15.1%, #F7F8FA 100%);
}

ion-footer.filter-opened,
ion-footer.filter-closed {
  display: none;
}

@media only screen and (max-width: 767px) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .filter-closed {
    .col-filter {
      display: none;
    }
    .col-listing {
      display: block;
    }
  }

  .filter-opened {
    .col-filter {
      display: block;
    }

    .col-listing {
      display: none;
    }
  }

  ion-footer.filter-opened {
    display: block;
  }

  ion-footer.filter-closed {
    display: none;
  }
}

.select_search_store_id {

  ion-label {
    white-space: normal !important;
  }

  .popover-content {
    min-width: 350px;
  }
}

.editor-inactive {
  .ck.ck-sticky-panel__content,
  ion-item {
    display: none;
  }
}

ion-header {
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 5%);
}

ion-header.add-border:after {
  display: inline!important;
}

.clear-header:after {
  display: none;
}

ion-header.add-border:after {
  display: inline;
}

.clear-header ion-toolbar {
  --border-width: 0 !important;
}
.candidate_name {
  text-transform: capitalize;
}

ion-modal,
ion-router-outlet {
  ion-header ion-buttons[slot="end"] ion-button {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--ion-color-primary);
    text-transform: none;
  }

  //ion-title,
  //ion-header {
  //    padding-left: 10px;
  //    padding-right: 10px;
  //}

  ion-title:not([size="small"]) {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--ion-color-custom-6);
  }

  ion-content {
    --padding-start: 30px;
    --padding-end: 30px;
  }
}

ckeditor {
  width: 100%;
  margin-top: 15px;
  display: block;
  padding: 0 5px;
}

.ckeditor-title {
  border: none;
  margin-bottom: -15px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.candidate-merge-select .popover-content {
  min-width: 350px;
}

.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slit-in-vertical {
	-webkit-animation: slit-in-vertical 0.45s ease-out both;
	        animation: slit-in-vertical 0.45s ease-out both;
}

.flip-in-hor-bottom {
	-webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

//bottom

@-webkit-keyframes slide-in-bottom {
  
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}

@keyframes slide-in-bottom {
  
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}

 @-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}

@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}

 @-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}

@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}

.fade-out {
  height: 0;
  animation: fadeOut 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
       opacity: 1;
  }

  to {
    opacity: 0;
  }
}

ion-calendar-modal {
  ion-header {
    padding-left: 0;
    padding-right: 0;
  }

  ion-button {
    color: unset !important;
    text-transform: none !important;
  }

  ion-toolbar {
    color: var(--ion-color-primary-contrast);
  }
}

.container {
  max-width: 1050px;
  margin: 0 auto !important;
}

ng5-slider {
  $handleSize: 12px;

  background: var(--ion-color-custom-2) !important;
  margin: 10px 0 15px !important;

  .ng5-slider-pointer {
      width: 12px !important;
      height: 12px !important;
      top: -4px !important;
      background-color: var(--ion-color-primary) !important;
      outline: none;

      &:after {
          content: none;
          display: none;
      }
  }

  .ng5-slider-selection {
      background: var(--ion-color-custom-3) !important;
  }

  .ng5-slider-bubble {
      display: none !important;
  }
}
.pull-right {
  float: right;
  clear: both;
}

.modal-change-password,
.modal-request-filter {

  --backdrop-opacity: 0.75;

  .modal-wrapper {
      width: 390px;
      height: 490px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 30px;
      border-radius: 30px;
      background-color: var(--ion-color-primary-contrast);
  }

  .drager {
      display: none;
  }
}

.modal-change-password .modal-wrapper {
  height: 467px;
}

@media only screen and (max-width: 768px) {
  .modal-change-password,
  .modal-request-filter {
      .modal-wrapper {
          height: 456px;
          position: fixed;
          width: 100%;
          bottom: 0;
          border-radius: 30px 30px 0 0;
      }

      .drager {
          display: block !important;
      }
  }

  .modal-change-password .modal-wrapper {
    height: 490px;
  }

  .modal-delivered-story  .modal-wrapper {
    height: 403px;
  }

  .close-story .modal-wrapper {
    height: 237px;
  }
}

.modal-delivered-story  .modal-wrapper {
  height: 403px;
}

.close-story .modal-wrapper {
  height: 237px;
}
[dir="ltr"].update-alert {
  padding-right: 52px;
  right: auto;
  text-align: left;

  .btn-refresh {
      margin-left: -16px;
      margin-right: auto;

      span {
          margin-left: 8px;
      }
  }

  .btn-close {
      right: 16px;
  }
}

[dir="rtl"].update-alert {
  padding-left: 52px;
  left: auto;
  text-align: right;

  .btn-refresh {
      margin-left: auto;
  }

  span {
      margin-right: 8px;
  }

  .btn-close {
      left: 16px;
  }
  .title {
      margin: auto;
  }
}

.update-alert {
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  letter-spacing: .2px;
  -webkit-align-items: center;
  align-items: center;
  background-color: var(--ion-color-dark);
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--ion-color-dark-contrast);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: 400;
  left: 0;
  margin: 24px;
  max-width: 640px;
  min-height: 52px;
  padding: 8px 24px;
  position: fixed;
  top: auto;

  .title {
      margin: 10px 24px 10px 0;
  }

  .wrapper {
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      position: relative;
  }

  .btn-refresh {
      font-size: 0;
      height: 40px;

      span {
          align-items: center;
          border: none;
          display: inline-flex;
          justify-content: center;
          position: relative;
          z-index: 0;
          -webkit-font-smoothing: antialiased;
          font-family: 'Google Sans', Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
          font-size: 14px;
          letter-spacing: .15px;
          background: none;
          border-radius: 4px;
          box-sizing: border-box;
          cursor: pointer;
          font-weight: 500;
          height: 40px;
          outline: none;
          color: var(--ion-color-custom-13);
          min-width: auto;
          padding: 0 8px;
          text-decoration: none;
      }
  }

  .btn-close {
      align-items: center;
      border: none;
      display: inline-flex;
      justify-content: center;
      outline: none;
      z-index: 0;
      cursor: pointer;
      position: absolute;
      top: 16px;
      ion-icon {
          font-size: 20px;
      }
  }
}

.txt-color-warning {
  color: var(--ion-color-warning, #ffc409);
}

.ck-content {
  height:170px!important;
}

ion-item.toggle-wrapper {
  --background: transparent;
  --inner-border-width: 0;
  --padding-start: 5px;
  --padding-end: 0;
  --inner-padding-end: 0;

  ion-toggle {
    padding-inline-end: 2px;
  }

  ion-label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--ion-color-custom-6);

    small {
      color: var(--ion-color-custom-5);
      font-size: 10px;
      clear: both;
      display: block;
    }
  }
}

.day ion-item.input-wrapper {
  --background: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary-contrast);
  color: var(--ion-color-custom-5);
  --color: var(--ion-color-custom-5);

  ion-input {
    color: var(--ion-color-custom-6);
  }

  ion-label {
    color: var(--ion-color-custom-5);
    --color: var(--ion-color-custom-5);
  }
}

ion-item.input-wrapper {
  --height: 48px;
  --border-color: var(--ion-color-custom-4);
  --border-width: 0px;
  --border-radius: 8px;
  margin-bottom: 16px;
  padding: 0;
  border: 1px solid var(--ion-color-custom-4);
  border-radius: 8px;

  ion-input {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
  }

  ion-label {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
  }
}
/*
ion-item.input-wrapper:not(.item-has-value) {
  ion-label {
    transform: translateY(75%);
  }
}*/

.candidate-option .popover-content,
.store-option .popover-content {
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.1)
}

.candidate-option {
  --width: 280px;

  .popover-content {
    min-width: 280px;
  }
}

.pull-left {
  float: left;
}

.tab-requests request-listing ion-card {
  margin-top: 0;
}

.popup-modal {
  text-align: revert;
  justify-content: flex-end;
  --height: 100%;

  .modal-wrapper {
      height: 100%;
  }
}

.date-popup-over{
   /*
    --background: rgba(40, 173, 218, 0.6);
    --backdrop-opacity: 0.6;
    --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
    --color: white;*/
    --width: 300px!important;

  ion-content {
  //  --background: rgba(40, 173, 218, 0.6);
  }
}

.date-popup-over::part(backdrop) {
 // background-color: rgb(6, 14, 106);
}

ion-header {
  //--background: var(--ion-color-dark-contrast);
  //background: var(--ion-color-dark-contrast);
  --background: var(--ion-tab-bar-background);
  background: var(--ion-tab-bar-background);
}

.day ion-content {

  --background: var(--ion-color-custom-9);

  ion-searchbar {
    --background: var(--ion-color-custom-8);
    background-color: var(--ion-color-custom-8);
    --placeholder-color: var(--ion-color-custom-7);
    color: var(--ion-color-custom-10);
    --icon-color: rgba(125, 125, 141, 0.7);
  }
}

ion-content {

  ion-searchbar {
    border-radius: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    --box-shadow: none;
    --min-height: 40px;
    --height: 40px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    
  }
}

.modal-sheet,
.footer-modal {
  --max-width: 800px;
  --border-radius: 30px;
}
 